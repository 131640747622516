import api from '@/api'

const state = {
  growingTerms: [],
  themes: [],
  npsData: [],
  csatData: []
}

const getters = {
  growingTerms: (state) => state.growingTerms,
  npsData: (state) => state.npsData,
  csatData: (state) => state.csatData
}

const actions = {
  async getTrends({ commit }, data) {
    console.log(data)
    try {
      const response = await api.get('/analytics/trends?type=' + data.type)
      console.log(response)
      commit('SET_GROWING_TERMS', response)

      return response
    } catch (error) {
      console.error(
        'Failed to retrieve trends:',
        error.response?.data.meta.message || error.message
      )
      throw new Error(
        error.response?.data?.meta.message || 'Failed to retrieve trends.'
      )
    }
  },
  async getThemes({ commit }, data) {
    console.log(data)
    try {
      const response = await api.get('/analytics/themes')
      console.log(response)
      commit('SET_THEMES', response)

      return response
    } catch (error) {
      console.error(
        'Failed to retrieve trends:',
        error.response?.data.meta.message || error.message
      )
      throw new Error(
        error.response?.data?.meta.message || 'Failed to retrieve trends.'
      )
    }
  },
  async getNPSData({ commit }, params) {
    try {
      const response = await api.get('/analytics/nps', {
        params: {
          start_date: params.start_date,
          end_date: params.end_date,
          source: params.source
        }
      })
      commit('SET_NPS_DATA', response.data)
      return response
    } catch (error) {
      console.error(
        'Failed to retrieve NPS data:',
        error.response?.data.meta.message || error.message
      )
      throw new Error(
        error.response?.data?.meta.message || 'Failed to retrieve NPS data.'
      )
    }
  },
  async getCSATData({ commit }, params) {
    try {
      const response = await api.get('/analytics/csat', {
        params: {
          start_date: params.start_date,
          end_date: params.end_date
        }
      })
      commit('SET_CSAT_DATA', response.data)
      return response
    } catch (error) {
      console.error(
        'Failed to retrieve NPS data:',
        error.response?.data.meta.message || error.message
      )
      throw new Error(
        error.response?.data?.meta.message || 'Failed to retrieve NPS data.'
      )
    }
  },
  setData({ commit }, data) {
    commit('SET_GROWING_TERMS', data)
  }
}

const mutations = {
  SET_GROWING_TERMS(state, growingTerms) {
    state.growingTerms = growingTerms
  },
  SET_THEMES(state, themes) {
    state.themes = themes
  },
  SET_NPS_DATA(state, npsData) {
    state.npsData = npsData
  },
  SET_CSAT_DATA(state, csatData) {
    state.csatData = csatData
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
