import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import DashboardView from '../views/DashboardView.vue'
import BillingView from '../views/BillingView.vue'
import ChannelsView from '../views/ChannelsView.vue'
import TrendAnalysisView from '../views/TrendAnalysisView.vue'
import NpsAnalysisView from '../views/NpsAnalysisView.vue'
import CsatAnalysisView from '../views/CsatAnalysisView.vue'

import CommentSearchView from '../views/CommentSearchView.vue'
import ThemeAnalysisView from '../views/ThemeAnalysisView'
import TeamMembersView from '../views/TeamMembersView'
import ChatView from '../views/ChatView'
import Cookies from 'js-cookie'
import store from '@/store'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'home',
      component: HomeView,
      meta: {
        title: 'Zadabra | Home'
      }
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: DashboardView,
      meta: {
        title: 'Zadabra | Dashboard'
      }
    },
    // {
    //   path: '/search',
    //   name: 'search',
    //   component: CommentSearchView,
    //   meta: {
    //     title: 'Zadabra | Search'
    //   }
    // },
    {
      path: '/channels',
      name: 'channels',
      component: ChannelsView,
      meta: {
        title: 'Zadabra | Channels'
      }
    },
    {
      path: '/trends',
      name: 'trends',
      component: TrendAnalysisView,
      meta: {
        title: 'Zadabra | Trends'
      }
    },
    {
      path: '/themes',
      name: 'themes',
      component: ThemeAnalysisView,
      meta: {
        title: 'Zadabra | Themes'
      }
    },
    {
      path: '/nps',
      name: 'nps',
      component: NpsAnalysisView,
      meta: {
        title: 'Zadabra | NPS'
      }
    },
    {
      path: '/csat',
      name: 'csat',
      component: CsatAnalysisView,
      meta: {
        title: 'Zadabra | CSAT'
      }
    },
    {
      path: '/chat',
      name: 'chat',
      component: ChatView,
      meta: {
        title: 'Zadabra | Chat'
      }
    },
    {
      path: '/team',
      name: 'team',
      component: TeamMembersView,
      meta: {
        title: 'Zadabra | Team'
      }
    },
    {
      path: '/billing',
      name: 'billing',
      component: BillingView,
      meta: {
        title: 'Zadabra | Billing'
      }
    }
  ]
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || 'Zadabra'

  const token = Cookies.get('auth_token')
  let isAuthenticated = false

  if (token) {
    try {
      const [, payload] = token.split('.')
      const decodedPayload = JSON.parse(window.atob(payload))

      store.commit('auth/SET_USER', {
        id: decodedPayload.uid,
        name: decodedPayload.uname,
        email: decodedPayload.uemail
      })
      store.commit('auth/SET_AUTH', true)
      store.commit('auth/SET_TOKEN', token)
      isAuthenticated = true
    } catch (error) {
      clearAuthStore()
    }
  } else {
    clearAuthStore()
  }

  if (!isAuthenticated && to.path !== '/') {
    store.commit('auth/SET_LOGIN_MODAL', true)
    store.commit('auth/SET_IS_LOGIN', true) // sets modal to login state instead of register state

    next('/')
  } else {
    next()
  }
})

function clearAuthStore() {
  store.commit('auth/SET_USER', null)
  store.commit('auth/SET_AUTH', false)
  store.commit('auth/SET_TOKEN', null)
}

export default router
