<!-- NPSDashboard.vue -->
<script setup>
import { ref, computed, onMounted, watch } from 'vue'
import BarChart from './BarChart.vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'

import { Pie } from 'vue-chartjs'
import { Download } from 'lucide-vue-next'
import jsPDF from 'jspdf'
import { Line } from 'vue-chartjs'
import ChartDataLabels from 'chartjs-plugin-datalabels'

import 'jspdf-autotable'
import {
  Chart as ChartJS,
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
} from 'chart.js'

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  ChartDataLabels
)

// Also import Line component

const store = useStore()
const route = useRoute()

const loading = ref(true)
const npsData = computed(() => store.getters['analytics/npsData'])

const start = new Date()
start.setDate(1)
start.setHours(0, 0, 0, 0)
const end = new Date()

// Create reactive dateRange object
const dateRange = ref({
  start: start,
  end: end
})

let displayStartDate =
  route.query.date_from ?? dateRange.value.start.toISOString().split('T')[0]

let displayEndDate =
  route.query.date_to ?? dateRange.value.end.toISOString().split('T')[0]

const getNPSData = async () => {
  try {
    loading.value = true
    const params = {
      start_date: displayStartDate,
      end_date: displayEndDate,
      source: route.query.source ?? 'app,survey_sparrow'
    }
    await store.dispatch('analytics/getNPSData', params)
  } catch (error) {
    console.error('Error:', error)
  } finally {
    loading.value = false
  }
}

const pieChartData = computed(() => {
  if (!npsData.value?.overall) return null
  return {
    labels: ['Detractors', 'Passives', 'Promoters'],
    datasets: [
      {
        data: [
          npsData.value.overall.detractors,
          npsData.value.overall.passives,
          npsData.value.overall.promoters
        ],
        backgroundColor: ['#ef4444', '#f59e0b', '#22c55e']
      }
    ]
  }
})

const languageChartData = computed(() => {
  if (!npsData.value?.by_language) return null
  return {
    labels: Object.keys(npsData.value.by_language).map((code) => {
      try {
        const languageNames = new Intl.DisplayNames(['en'], {
          type: 'language'
        })
        return languageNames.of(code)
      } catch (error) {
        return code
      }
    }),
    datasets: [
      {
        label: 'NPS Score',
        data: Object.values(npsData.value.by_language).map(
          (data) => data.nps_score
        ),
        datalabels: {
          color: '#ffffff',
          anchor: 'end',
          align: 'top',
          font: {
            weight: 'bold'
          }
        },
        backgroundColor: '#3b82f6'
      },
      {
        label: 'Total Responses',
        data: Object.values(npsData.value.by_language).map(
          (data) => data.total_responses
        ),
        datalabels: {
          color: '#ffffff',
          anchor: 'end',
          align: 'top',
          font: {
            weight: 'bold'
          }
        },
        backgroundColor: '#64748b'
      }
    ]
  }
})

const industryChartData = computed(() => {
  if (!npsData.value?.by_industry) return null
  return {
    labels: Object.keys(npsData.value.by_industry),
    datasets: [
      {
        label: 'NPS Score',
        data: Object.values(npsData.value.by_industry).map(
          (data) => data.nps_score
        ),
        backgroundColor: '#3b82f6',
        datalabels: {
          color: '#ffffff',
          anchor: 'end',
          align: 'top',
          font: {
            weight: 'bold'
          }
        }
      },
      {
        label: 'Total Responses',
        data: Object.values(npsData.value.by_industry).map(
          (data) => data.total_responses
        ),
        backgroundColor: '#64748b',
        datalabels: {
          color: '#ffffff',
          anchor: 'end',
          align: 'top',
          font: {
            weight: 'bold'
          }
        }
      }
    ]
  }
})

const chartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: true,
      position: 'top',
      labels: {
        color: '#fff',
        font: {
          size: 12
        }
      }
    },
    tooltip: {
      enabled: true,
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
      titleColor: '#fff',
      bodyColor: '#fff',
      padding: 12,
      displayColors: true
    }
  },
  scales: {
    y: {
      ticks: { color: '#fff' },
      grid: {
        color: '#374151'
      }
    },
    x: {
      ticks: { color: '#fff' },
      grid: {
        color: '#374151'
      }
    }
  }
}

const dailyChartData = computed(() => {
  if (!npsData.value?.daily_metrics) return null

  const sortedDates = Object.keys(npsData.value.daily_metrics).sort()
  const metrics = sortedDates.map((date) => npsData.value.daily_metrics[date])

  return {
    labels: sortedDates,
    datasets: [
      {
        label: 'NPS Score',
        data: metrics.map((m) => m.nps_score),
        borderColor: '#3b82f6',
        backgroundColor: 'rgba(59, 130, 246, 0.1)',
        tension: 0.4,
        fill: true,
        yAxisID: 'y'
      },
      {
        label: 'Total Responses',
        data: metrics.map((m) => m.responses),
        borderColor: '#918599',
        backgroundColor: 'rgba(34, 197, 94, 0.1)',
        tension: 0.4,
        fill: true,
        yAxisID: 'y1'
      }
    ]
  }
})

// Add this computed property after the existing dailyChartData
const cumulativeChartData = computed(() => {
  if (!npsData.value?.daily_metrics) return null

  const sortedDates = Object.keys(npsData.value.daily_metrics).sort()
  const metrics = sortedDates.map((date) => npsData.value.daily_metrics[date])

  let cumulativeResponses = 0
  let cumulativePromoters = 0
  let cumulativeDetractors = 0

  const cumulativeData = metrics.reduce((acc, metric, index) => {
    // Calculate running totals
    const totalPromoters = metrics
      .slice(0, index + 1)
      .reduce((sum, m) => sum + m.promoters, 0)
    const totalDetractors = metrics
      .slice(0, index + 1)
      .reduce((sum, m) => sum + m.detractors, 0)
    const totalResponses = metrics
      .slice(0, index + 1)
      .reduce((sum, m) => sum + m.responses, 0)

    // Calculate current NPS based on all responses up to this point
    const currentNPS = Math.round(
      ((totalPromoters - totalDetractors) / totalResponses) * 100
    )

    return [
      ...acc,
      {
        responses: totalResponses,
        nps: currentNPS
      }
    ]
  }, [])

  return {
    labels: sortedDates,
    datasets: [
      {
        label: 'Overall NPS Score',
        data: cumulativeData.map((d) => d.nps),
        borderColor: '#8b5cf6', // Purple
        backgroundColor: 'rgba(139, 92, 246, 0.1)',
        tension: 0.4,
        fill: true,
        yAxisID: 'y'
      },
      {
        label: 'Cumulative Responses',
        data: cumulativeData.map((d) => d.responses),
        borderColor: '#f59e0b', // Amber
        backgroundColor: 'rgba(245, 158, 11, 0.1)',
        tension: 0.4,
        fill: true,
        yAxisID: 'y1'
      }
    ]
  }
})

const cumulativeChartOptions = computed(() => ({
  responsive: true,
  maintainAspectRatio: false,
  interaction: {
    mode: 'index',
    intersect: false
  },
  plugins: {
    legend: {
      display: true,
      position: 'top',
      labels: {
        color: '#fff',
        font: {
          size: 12
        }
      }
    },
    tooltip: {
      enabled: true,
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
      titleColor: '#fff',
      bodyColor: '#fff',
      padding: 12,
      callbacks: {
        label: function (context) {
          let label = context.dataset.label || ''
          if (label) {
            label += ': '
          }
          if (context.dataset.yAxisID === 'y') {
            label += context.parsed.y + ' NPS'
          } else {
            label += context.parsed.y.toFixed(0) + ' Responses'
          }
          return label
        }
      }
    },
    datalabels: {
      display: false
    }
  },
  scales: {
    x: {
      ticks: {
        color: '#fff',
        maxRotation: 45,
        minRotation: 45
      },
      grid: {
        color: '#374151'
      }
    },
    y: {
      type: 'linear',
      display: true,
      position: 'left',
      ticks: { color: '#fff' },
      grid: {
        color: '#374151'
      },
      title: {
        display: true,
        text: 'NPS Score',
        color: '#fff'
      },
      min: -100,
      max: 100
    },
    y1: {
      type: 'linear',
      display: true,
      position: 'right',
      ticks: { color: '#fff' },
      grid: {
        drawOnChartArea: false
      },
      title: {
        display: true,
        text: 'Cumulative Responses',
        color: '#fff'
      },
      min: 0
    }
  }
}))

const lineChartOptions = computed(() => ({
  responsive: true,
  maintainAspectRatio: false,
  interaction: {
    mode: 'index',
    intersect: false
  },
  plugins: {
    legend: {
      display: true,
      position: 'top',
      labels: {
        color: '#fff',
        font: {
          size: 12
        }
      }
    },
    tooltip: {
      enabled: true,
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
      titleColor: '#fff',
      bodyColor: '#fff',
      padding: 12,
      callbacks: {
        label: function (context) {
          let label = context.dataset.label || ''
          if (label) {
            label += ': '
          }
          if (context.dataset.yAxisID === 'y') {
            label += context.parsed.y
          } else {
            label += context.parsed.y.toFixed(0)
          }
          return label
        }
      }
    },
    datalabels: {
      display: false
    }
  },
  scales: {
    x: {
      ticks: {
        color: '#fff',
        maxRotation: 45,
        minRotation: 45
      },
      grid: {
        color: '#374151'
      }
    },
    y: {
      type: 'linear',
      display: true,
      position: 'left',
      ticks: { color: '#fff' },
      grid: {
        color: '#374151'
      },
      title: {
        display: true,
        text: 'NPS Score',
        color: '#fff'
      },
      min: -100,
      max: 100
    },
    y1: {
      type: 'linear',
      display: true,
      position: 'right',
      ticks: { color: '#fff' },
      grid: {
        drawOnChartArea: false
      },
      title: {
        display: true,
        text: 'Total Responses',
        color: '#fff'
      },
      min: 0
    }
  }
}))

const pieChartRef = ref(null)
const languageChartRef = ref(null)
const industryChartRef = ref(null)

const generatePDF = () => {
  try {
    const doc = new jsPDF()

    // Colors
    const colors = {
      primary: [59, 130, 246],
      lightGray: [245, 247, 250],
      darkGray: [31, 41, 55],
      white: [255, 255, 255]
    }

    // Cover page
    doc.setFillColor(...colors.primary)
    doc.rect(0, 0, 220, 297, 'F')

    doc.setDrawColor(...colors.white)
    doc.setLineWidth(0.5)
    doc.line(20, 75, 190, 75)

    doc.setTextColor(...colors.white)
    doc.setFontSize(42)
    doc.text('NPS Analysis', 20, 50)

    doc.setFontSize(20)
    doc.text('Dashboard Report', 20, 95)

    doc.setFontSize(12)
    doc.text(`Generated ${new Date().toLocaleDateString()}`, 20, 115)
    doc.text(
      `Date Range: ${npsData.value.date_range.from} - ${npsData.value.date_range.to}`,
      20,
      130
    )

    // Key stats on cover
    doc.setFontSize(14)
    doc.text('Summary Statistics', 20, 170)

    // Stats boxes
    const stats = [
      {
        label: 'Total Responses',
        value: npsData.value.overall.total_responses
      },
      {
        label: 'Overall NPS',
        value: npsData.value.overall.nps_score
      },
      {
        label: 'Target Industry NPS',
        value: npsData.value.target_industry_true.nps_score
      }
    ]

    stats.forEach(({ label, value }, index) => {
      const yPos = 180 + index * 40
      doc.setFillColor(...colors.darkGray)
      doc.roundedRect(20, yPos, 170, 30, 3, 3, 'F')
      doc.setFontSize(12)
      doc.text(label, 30, yPos + 12)
      doc.setFontSize(16)
      doc.text(value.toString(), 30, yPos + 25)
    })

    // Distribution page
    doc.addPage()

    doc.setFillColor(...colors.darkGray)
    doc.rect(0, 0, 220, 25, 'F')
    doc.setTextColor(...colors.white)
    doc.setFontSize(16)
    doc.text('Response Distribution', 20, 17)

    // Distribution table
    const distributionData = [
      ['Category', 'Count', 'Percentage'],
      [
        'Promoters',
        npsData.value.overall.promoters,
        (
          (npsData.value.overall.promoters /
            npsData.value.overall.total_responses) *
          100
        ).toFixed(1) + '%'
      ],
      [
        'Passives',
        npsData.value.overall.passives,
        (
          (npsData.value.overall.passives /
            npsData.value.overall.total_responses) *
          100
        ).toFixed(1) + '%'
      ],
      [
        'Detractors',
        npsData.value.overall.detractors,
        (
          (npsData.value.overall.detractors /
            npsData.value.overall.total_responses) *
          100
        ).toFixed(1) + '%'
      ]
    ]

    doc.autoTable({
      startY: 35,
      head: [distributionData[0]],
      body: distributionData.slice(1),
      styles: {
        fontSize: 10,
        cellPadding: 8
      },
      headStyles: {
        fillColor: [...colors.primary],
        textColor: 255,
        fontSize: 11,
        fontStyle: 'bold'
      },
      alternateRowStyles: {
        fillColor: [...colors.lightGray]
      },
      margin: { left: 20, right: 20 }
    })

    // Language Analysis page
    if (npsData.value.by_language) {
      doc.addPage()

      doc.setFillColor(...colors.darkGray)
      doc.rect(0, 0, 220, 25, 'F')
      doc.setTextColor(...colors.white)
      doc.setFontSize(16)
      doc.text('NPS by Language', 20, 17)

      const languageData = Object.entries(npsData.value.by_language).map(
        ([code, data]) => {
          try {
            const languageNames = new Intl.DisplayNames(['en'], {
              type: 'language'
            })
            return [
              languageNames.of(code),
              data.nps_score,
              data.total_responses
            ]
          } catch (error) {
            return [code, data.nps_score, data.total_responses]
          }
        }
      )

      doc.autoTable({
        startY: 35,
        head: [['Language', 'NPS Score', 'Total Responses']],
        body: languageData,
        styles: {
          fontSize: 10,
          cellPadding: 8
        },
        headStyles: {
          fillColor: [...colors.primary],
          textColor: 255,
          fontSize: 11,
          fontStyle: 'bold'
        },
        alternateRowStyles: {
          fillColor: [...colors.lightGray]
        },
        margin: { left: 20, right: 20 }
      })
    }

    // Industry Analysis page
    if (npsData.value.by_industry) {
      doc.addPage()

      doc.setFillColor(...colors.darkGray)
      doc.rect(0, 0, 220, 25, 'F')
      doc.setTextColor(...colors.white)
      doc.setFontSize(16)
      doc.text('NPS by Industry', 20, 17)

      const industryData = Object.entries(npsData.value.by_industry).map(
        ([industry, data]) => {
          return [industry, data.nps_score, data.total_responses]
        }
      )

      doc.autoTable({
        startY: 35,
        head: [['Industry', 'NPS Score', 'Total Responses']],
        body: industryData,
        styles: {
          fontSize: 10,
          cellPadding: 8
        },
        headStyles: {
          fillColor: [...colors.primary],
          textColor: 255,
          fontSize: 11,
          fontStyle: 'bold'
        },
        alternateRowStyles: {
          fillColor: [...colors.lightGray]
        },
        margin: { left: 20, right: 20 }
      })
    }

    // Generate and download
    const pdfOutput = doc.output('blob')
    const url = window.URL.createObjectURL(pdfOutput)
    const link = document.createElement('a')
    link.href = url
    link.download = 'nps-analysis-report.pdf'
    link.click()
    window.URL.revokeObjectURL(url)
  } catch (error) {
    console.error('Error generating PDF:', error)
  }
}

onMounted(() => {
  getNPSData()
})
</script>

<template>
  <div v-if="loading"><div class="spinner"></div></div>

  <div
    v-else-if="!npsData"
    class="min-h-screen flex items-center justify-center"
  >
    <div class="text-white">No data available</div>
  </div>
  <div v-else class="min-h-screen">
    <div class="flex justify-between items-center mb-8">
      <h2 class="text-xl font-semibold text-white">
        NPS Dashboard ({{ displayStartDate }} - {{ displayEndDate }})
      </h2>

      <!-- Export Button -->
      <button
        @click="generatePDF"
        class="flex items-center gap-2 px-4 py-2 bg-blue-600 hover:bg-blue-500 text-white rounded-lg transition-colors duration-200"
      >
        <Download class="w-5 h-5" />
        <span>Export Report</span>
      </button>
    </div>

    <!-- Stats Cards -->
    <div class="grid grid-cols-1 md:grid-cols-3 gap-4 mb-6">
      <div class="bg-gray-900 rounded-lg p-4">
        <p class="text-sm text-gray-400">Total Responses</p>
        <p class="text-2xl font-bold text-white">
          {{ npsData.overall.total_responses }}
        </p>
      </div>
      <div class="bg-gray-900 rounded-lg p-4">
        <p class="text-sm text-gray-400">Overall NPS</p>
        <p class="text-2xl font-bold text-white">
          {{ npsData.overall.nps_score }}
        </p>
      </div>
      <div class="bg-gray-900 rounded-lg p-4">
        <p class="text-sm text-gray-400">Target Industry NPS</p>
        <p class="text-2xl font-bold text-white">
          {{ npsData.target_industry_true.nps_score }}
        </p>
      </div>
    </div>

    <!-- Distribution Chart -->
    <div v-if="pieChartData" class="bg-gray-900 rounded-lg p-4 mb-6">
      <h3 class="text-lg font-semibold text-white mb-4">
        Response Distribution
      </h3>
      <div class="h-96">
        <Pie
          :data="pieChartData"
          :options="{
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                display: true,
                position: 'top',
                labels: {
                  color: '#fff',
                  font: { size: 12 }
                }
              },
              tooltip: {
                enabled: true,
                backgroundColor: 'rgba(0, 0, 0, 0.8)',
                titleColor: '#fff',
                bodyColor: '#fff',
                padding: 12
              },
              datalabels: {
                color: '#fff',
                formatter: (value, ctx) => {
                  const label = ctx.chart.data.labels[ctx.dataIndex]
                  const percentage = (
                    (value / npsData.overall.total_responses) *
                    100
                  ).toFixed(1)
                  return `${label}\n${value} (${percentage}%)`
                },
                font: {
                  weight: 'bold',
                  size: 12
                }
              }
            },
            scales: {
              x: { display: false },
              y: { display: false }
            }
          }"
        />
      </div>
    </div>

    <!-- Daily Trends Chart -->
    <div v-if="dailyChartData" class="bg-gray-900 rounded-lg p-4 mb-6">
      <h3 class="text-lg font-semibold text-white mb-4">Daily NPS Trends</h3>
      <div class="h-[400px]">
        <Line :data="dailyChartData" :options="lineChartOptions" />
      </div>
    </div>

    <!-- Cumulative Trends Chart -->
    <!-- Cumulative Trends Chart -->
    <div v-if="cumulativeChartData" class="bg-gray-900 rounded-lg p-4 mb-6">
      <h3 class="text-lg font-semibold text-white mb-4">
        Cumulative NPS Trend
      </h3>
      <div class="h-[400px]">
        <Line :data="cumulativeChartData" :options="cumulativeChartOptions" />
      </div>
    </div>

    <!-- Language Chart -->
    <div v-if="languageChartData" class="bg-gray-900 rounded-lg p-4 mb-6">
      <h3 class="text-lg font-semibold text-white mb-4">NPS by Language</h3>
      <div class="h-[400px]">
        <BarChart :chart-data="languageChartData" :options="chartOptions" />
      </div>
    </div>

    <!-- Industry Chart -->
    <div v-if="industryChartData" class="bg-gray-900 rounded-lg p-4">
      <h3 class="text-lg font-semibold text-white mb-4">NPS by Industry</h3>
      <div class="h-[600px]">
        <BarChart
          :chart-data="industryChartData"
          :options="{
            ...chartOptions,
            indexAxis: 'y',
            scales: {
              ...chartOptions.scales,
              y: {
                ...chartOptions.scales.y,
                ticks: {
                  ...chartOptions.scales.y.ticks,
                  font: {
                    size: 11
                  }
                }
              }
            }
          }"
        />
      </div>
    </div>
  </div>
</template>